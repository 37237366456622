/* globals document, window, localStorage, WEBFLOW_API_HOST, WEBFLOW_DEFAULT_HOST */
/* eslint-disable no-var */
/**
 * Webflow: Editor loader
 */
var Webflow = require('./webflow-lib');

Webflow.define(
  'edit',
  (module.exports = function ($, _, options) {
    options = options || {};

    // Exit early in test env or when inside an iframe
    if (Webflow.env('test') || Webflow.env('frame')) {
      // Allow test fixtures to continue
      if (!options.fixture && !inCypress()) {
        return {exit: 1};
      }
    }

    var api = {};
    var $win = $(window);
    var $html = $(document.documentElement);
    var location = document.location;
    var hashchange = 'hashchange';
    var loaded;
    var loadEditor = options.load || load;
    var hasLocalStorage = false;

    try {
      // Check localStorage for editor data
      hasLocalStorage =
        localStorage &&
        localStorage.getItem &&
        localStorage.getItem('WebflowEditor');
    } catch (e) {
      // SecurityError: browser storage has been disabled
    }

    if (hasLocalStorage) {
      loadEditor();
    } else if (location.search) {
      // Check url query for `edit` parameter or any url ending in `?edit`
      if (
        /[?&](edit)(?:[=&?]|$)/.test(location.search) ||
        /\?edit$/.test(location.href)
      ) {
        loadEditor();
      }
    } else {
      // Check hash fragment to support `#hash?edit`
      $win.on(hashchange, checkHash).triggerHandler(hashchange);
    }

    function checkHash() {
      if (loaded) {
        return;
      }
      // Load editor when hash contains `?edit`
      if (/\?edit/.test(location.hash)) {
        loadEditor();
      }
    }

    function load() {
      loaded = true;
      // Predefine global immediately to benefit Webflow.env
      window.WebflowEditor = true;
      $win.off(hashchange, checkHash);
      checkThirdPartyCookieSupport(function (thirdPartyCookiesSupported) {
        $.ajax({
          url: cleanSlashes(WEBFLOW_API_HOST + '/api/editor/view'),
          data: {siteId: $html.attr('data-wf-site')},
          xhrFields: {withCredentials: true},
          dataType: 'json',
          crossDomain: true,
          success: success(thirdPartyCookiesSupported),
        });
      });
    }

    function success(thirdPartyCookiesSupported) {
      return function (data) {
        if (!data) {
          console.error('Could not load editor data');
          return;
        }
        data.thirdPartyCookiesSupported = thirdPartyCookiesSupported;
        getScript(prefix(data.scriptPath), function () {
          window.WebflowEditor(data);
        });
      };
    }

    function getScript(url, done) {
      $.ajax({type: 'GET', url, dataType: 'script', cache: true}).then(
        done,
        error
      );
    }

    function error(jqXHR, textStatus, errorThrown) {
      console.error('Could not load editor script: ' + textStatus);
      throw errorThrown;
    }

    function prefix(url) {
      return url.indexOf('//') >= 0
        ? url
        : cleanSlashes(WEBFLOW_API_HOST + url);
    }

    function cleanSlashes(url) {
      return url.replace(/([^:])\/\//g, '$1/');
    }

    function checkThirdPartyCookieSupport(callback) {
      var iframe = window.document.createElement('iframe');
      iframe.src = WEBFLOW_DEFAULT_HOST + '/site/third-party-cookie-check.html';
      iframe.style.display = 'none';
      iframe.sandbox = 'allow-scripts allow-same-origin';

      var handleMessage = function (event) {
        if (event.data === 'WF_third_party_cookies_unsupported') {
          cleanUpCookieCheckerIframe(iframe, handleMessage);
          callback(false);
        } else if (event.data === 'WF_third_party_cookies_supported') {
          cleanUpCookieCheckerIframe(iframe, handleMessage);
          callback(true);
        }
      };

      iframe.onerror = function () {
        cleanUpCookieCheckerIframe(iframe, handleMessage);
        callback(false);
      };

      window.addEventListener('message', handleMessage, false);
      window.document.body.appendChild(iframe);
    }

    function cleanUpCookieCheckerIframe(iframe, listener) {
      window.removeEventListener('message', listener, false);
      iframe.remove();
    }

    // Export module
    return api;
  })
);

function inCypress() {
  try {
    return window.top.__Cypress__;
  } catch (e) {
    return false;
  }
}
